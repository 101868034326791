import useFetch from './useFetch';

const credentials = 'include';

const createUser = (userName, email, password) =>
  useFetch('/users', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    credentials,
    body: JSON.stringify({ userName, email, password }),
  });

const login = (userName, password) =>
  useFetch('/login', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    credentials,
    body: JSON.stringify({ userName, password }),
  });

const getPost = (id) =>
  useFetch(`/post/${id}`, {
    method: 'GET',
    credentials,
  });

const getPosts = () =>
  useFetch('/posts', {
    method: 'GET',
    credentials,
  });

/* Login required requests */

const isAuth = () =>
  useFetch('/isAuth', {
    method: 'GET',
    credentials,
  });

const isEditAuth = (postId) =>
  useFetch(`/post/edit/${postId}`, {
    method: 'GET',
    credentials,
  });

const logout = () =>
  useFetch('/logout', {
    method: 'POST',
    credentials,
  });

const publishNewPost = (title, elements, tag) =>
  useFetch('/posts', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    credentials,
    body: JSON.stringify({ title, elements, tag }),
  });

const editExistingPost = (title, newTitle, elements, tag) =>
  useFetch('/posts', {
    method: 'PATCH',
    headers: {
      'content-type': 'application/json',
    },
    credentials,
    body: JSON.stringify({ title, newTitle, elements, tag }),
  });

const addReaction = (postId, verb, data = {}) =>
  useFetch('/reactions', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    credentials,
    body: JSON.stringify({ postId, verb, data }),
  });

const removeReaction = (reactionId) =>
  useFetch(`/reaction/${reactionId}`, {
    method: 'DELETE',
    credentials,
  });

const uploadImage = (body) =>
  useFetch('/upload/images', {
    method: 'POST',
    credentials,
    body,
  });

const getUploadedImages = () =>
  useFetch('/upload/images', {
    method: 'GET',
    credentials,
  });

export {
  createUser,
  login,
  logout,
  isAuth,
  isEditAuth,
  publishNewPost,
  editExistingPost,
  getPost,
  getPosts,
  addReaction,
  removeReaction,
  uploadImage,
  getUploadedImages,
};
