import { createContext, useContext } from 'react';

const SigninModalContext = createContext();

/**
 * @function useSigninModalContext
 * @return {{
 * signinModalType: string;
 * setSigninModalType: Function;
 * }}
 */

const useSigninModalContext = () => useContext(SigninModalContext);

export { SigninModalContext, useSigninModalContext };
