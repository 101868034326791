import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import LabeledInputWithError from '../common/LabeledInputWithError';
import PopupCard from '../Popup/PopupCard';
import {
  dynamicStateReducer,
  handleSetReducerState,
  useThunkReducer,
} from '../../utils/dynamicStateReducer';
import { createUser, login } from '../../utils/mainApi';
import { useCurrentUserContext } from '../../contexts/CurrentUserContext';

const reducerInitialState = {
  userName: '',
  email: '',
  password: '',
};

export default function SigninModal(props) {
  const { popupType, handleClose, handleLinkClick } = props;
  const { setCurrentUser } = useCurrentUserContext();
  const [values, thunkDispatch] = useThunkReducer(dynamicStateReducer, reducerInitialState);
  const { userName, email, password } = useMemo(() => values, [values]);
  const isSignUp = useMemo(() => popupType === 'signup', [popupType]);
  const upIn = useMemo(() => (isSignUp ? 'Up' : 'In'), [isSignUp]);
  const handleChange = useCallback(
    (event) => {
      const { name, value } = event.target;
      handleSetReducerState(thunkDispatch, { [name]: value });
    },
    [thunkDispatch]
  );
  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      if (isSignUp) {
        createUser(userName, email, password).then((res) => {
          if (!(res instanceof Error)) {
            handleClose();
          }
        });
      } else {
        login(userName, password).then((res) => {
          if (!(res instanceof Error)) {
            const { userName: resUserName, email: resEmail } = res;
            setCurrentUser({ userName: resUserName, email: resEmail });
            handleClose();
          }
        });
      }
    },
    [isSignUp, handleClose, values]
  );
  useEffect(() => {
    handleSetReducerState(thunkDispatch, reducerInitialState);
  }, [popupType]);
  return (
    <PopupCard
      handleClose={handleClose}
      isOpen={Boolean(popupType)}
      name="signinModal"
      popupTitle={`Sign ${upIn}`}
      popupBottomLink={`${isSignUp ? 'Already' : 'Not'} a User? Sign ${
        isSignUp ? 'In' : 'Up'
      } Now!`}
      handleLinkClick={handleLinkClick}
    >
      <form className="popup__form" onSubmit={handleSubmit}>
        <div className="popup__lables">
          {isSignUp ? (
            <LabeledInputWithError
              className="popup__label"
              title="Email"
              inputName="email"
              inputValue={email}
              inputOnChange={handleChange}
            />
          ) : null}
          <LabeledInputWithError
            className="popup__label"
            title="User Name"
            inputName="userName"
            inputValue={userName}
            inputOnChange={handleChange}
          />
          <LabeledInputWithError
            className="popup__label"
            title="Password"
            inputName="password"
            inputType="password"
            inputValue={password}
            inputOnChange={handleChange}
          />
        </div>
        <button type="submit" className="popup__save-button">
          Sign {upIn}
        </button>
      </form>
    </PopupCard>
  );
}

SigninModal.propTypes = {
  popupType: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleLinkClick: PropTypes.func.isRequired,
};
