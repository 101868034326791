import React, { useCallback } from 'react';
import { useCurrentUserContext } from '../../contexts/CurrentUserContext';
import { useSigninModalContext } from '../../contexts/SigninModalContext';
import { logout } from '../../utils/mainApi';
import HeaderNavItem from './HeaderNavItem';

const navItems = [
  { title: 'Blog', link: '/blog' },
  { title: 'Create Post', link: '/blog/new-post', login: true },
  { title: 'About', link: '/about' },
];

export default function HeaderNav() {
  const { currentUser, setCurrentUser } = useCurrentUserContext();
  const { setSigninModalType } = useSigninModalContext();
  const handleUserButtonClick = useCallback(() => {
    if (currentUser?.userName) {
      logout();
      setCurrentUser({});
    } else {
      setSigninModalType('signin');
    }
  }, [currentUser]);
  return (
    <nav className="header__nav">
      <ul className="header__nav-links">
        {navItems.map(({ title, link, login }) =>
          (login && currentUser.email) || !login ? (
            <HeaderNavItem key={title} title={title} link={link} />
          ) : null
        )}
        <li className="header__nav-user-container">
          <button className="header__nav-user" type="button" onClick={handleUserButtonClick}>
            {currentUser?.userName || 'Login'}
          </button>
        </li>
      </ul>
    </nav>
  );
}
