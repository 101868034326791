import React, { lazy, Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { CurrentUserContext } from '../contexts/CurrentUserContext';
import { SigninModalContext } from '../contexts/SigninModalContext';
import { isAuth } from '../utils/mainApi';
import Header from './Header/Header';
import SigninModal from './Modal/SigninModal';

const Blog = lazy(() => import('./Blog/Blog'));
const BlogPost = lazy(() => import('./Blog/BlogPost'));
const BlogCreatePost = lazy(() => import('./Blog/BlogCreatePost/BlogCreatePost'));
const About = lazy(() => import('./About/About'));
const RedirectPage = lazy(() => import('./RedirectPage'));

export default function App() {
  const [currentUser, setCurrentUser] = useState({
    userName: '',
    email: '',
  });
  const [signinModalType, setSigninModalType] = useState('');
  const handleSigninModalClose = useCallback(() => {
    setSigninModalType('');
  }, []);
  const handleSigninModalLinkClick = useCallback(() => {
    if (signinModalType === 'signin') {
      setSigninModalType('signup');
    } else {
      setSigninModalType('signin');
    }
  }, [signinModalType]);
  useEffect(() => {
    isAuth().then((res) => {
      if (!(res instanceof Error)) {
        setCurrentUser(res.user);
      }
    });
  }, []);
  return (
    <CurrentUserContext.Provider
      value={useMemo(() => ({ currentUser, setCurrentUser }), [currentUser])}
    >
      <SigninModalContext.Provider
        value={useMemo(() => ({ signinModalType, setSigninModalType }), [signinModalType])}
      >
        <div className="page">
          <main className="main">
            <Header />
            <Suspense>
              <Routes>
                <Route path="/blog">
                  <Route path="post/:postId" element={<BlogPost />} />
                  <Route path="new-post" element={<BlogCreatePost />} />
                  <Route path="" element={<Blog />} />
                </Route>
                <Route path="/about" element={<About />} />
                <Route path="/*" element={<RedirectPage />} />
              </Routes>
            </Suspense>
            <SigninModal
              popupType={signinModalType}
              handleClose={handleSigninModalClose}
              handleLinkClick={handleSigninModalLinkClick}
            />
          </main>
        </div>
      </SigninModalContext.Provider>
    </CurrentUserContext.Provider>
  );
}
