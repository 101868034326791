/* eslint-disable react/prop-types */
import React from 'react';
import LabeledInput from './LabeledInput';

/**
 * @function LabeledInputWithError
 * @param {{
 * title: string;
 * inputType: string;
 * inputName: string;
 * inputValue: string | number | undefined | null;
 * inputPlaceholder?: string?;
 * inputOnChange?: function?;
 * isPresonal?: boolean;
 * disabled?: boolean;
 * errorMessage: string;
 * className?: string;
 * children: Node;
 * }} props
 * @returns
 */

export default function LabeledInputWithError(props) {
  const {
    title,
    inputType,
    inputName,
    inputValue,
    inputPlaceholder,
    inputOnChange = () => {},
    isPresonal,
    disabled,
    errorMessage,
    required = false,
    noValidate = false,
    className,
    children,
  } = props;
  return (
    <LabeledInput
      title={title}
      className={className}
      inputPlaceholder={inputPlaceholder}
      inputName={inputName}
      inputValue={inputValue}
      inputOnChange={inputOnChange}
      disabled={disabled}
      isPresonal={isPresonal}
      noValidate={noValidate}
      required={required}
    >
      {children || <input type={inputType} />}
      <span className={`${className}-error`}>{errorMessage}</span>
    </LabeledInput>
  );
}
