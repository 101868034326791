/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';

/**
 * @function LabeledInput
 * @param {{
 * title: string;
 * inputType: string;
 * inputName: string;
 * inputValue: string | number | undefined | null;
 * inputPlaceholder?: string?;
 * inputOnChange?: function?;
 * style?: Object;
 * inputStyle?: Object;
 * disabled?: boolean;
 * required?: boolean;
 * className?: string;
 * noValidate?: boolean;
 * children: Node;
 * }} props
 * @returns
 */

export default function LabeledInput(props) {
  const {
    title,
    inputType,
    inputName,
    inputValue,
    inputPlaceholder,
    inputOnChange = () => {},
    style,
    inputStyle,
    disabled,
    required,
    className,
    noValidate = false,
    children,
  } = props;
  const kids = useMemo(
    () => (Array.isArray(children) ? children : [children].filter((value) => value?.props)),
    [children]
  );
  return (
    <label htmlFor={inputName} className={className} style={style}>
      {title}
      {kids.length > 0 ? (
        kids.map((kid, index) =>
          index === 0
            ? {
                ...kid,
                key: index,
                props: {
                  ...kid.props,
                  placeholder: inputPlaceholder,
                  id: inputName,
                  name: inputName,
                  value: inputValue,
                  onChange: inputOnChange,
                  style: inputStyle,
                  disabled,
                  required,
                  'data-no-validate': noValidate,
                },
              }
            : { ...kid, key: index }
        )
      ) : (
        <input
          type={inputType}
          placeholder={inputPlaceholder}
          id={inputName}
          name={inputName}
          value={inputValue}
          onChange={inputOnChange}
          style={inputStyle}
          disabled={disabled}
          data-no-validate={noValidate}
        />
      )}
    </label>
  );
}
