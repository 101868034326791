import { useReducer } from 'react';
import { isFunction } from 'lodash';

/**
 * @module dynamicStateReducer
 */

const reducerActions = {
  SET_NEW_STATE: 'SET_NEW_STATE',
};

/**
 * @function dynamicStateReducer
 * @description Create a reducer with a dynamic state.
 * @example <caption>Example reducer usage</caption>
 * // yourReducer.jsx
 * import { reducerActions } from "./dynamicStateReducer";
 * // Initial State
 * const initialState = {
 * searchBoxOptions: [],
 * financialKeysTooltips: {},
 * choosenPlan: 'Free',
 * };
 * // Example reducer setState function
 * const handleSetChoosenPlan = (dispatch, choosenPlan) => {
 * dispatch({
 * type: reducerActions.SET_NEW_STATE,
 * payload: { choosenPlan },
 * });
 * };
 * // Example reducer setAll function
 * const handleSetAll = (dispatch, all) => {
 * dispatch({
 * type: reducerActions.SET_NEW_STATE,
 * payload: all,
 * });
 * };
 * export {
 * initialState,
 * handleSetChoosenPlan,
 * handleSetAll,
 * };
 * // yourComponent.jsx
 * import React, { useCallback, useMemo, useState } from 'react';
 * import useThunkReducer from '../context/useThunkReducer';
 * import { dynamicStateReducer } from '../context/dynamicStateReducer';
 * import { handleSetChoosenPlan, handleSetAll, initialState } from '../context/indexReducer';
 * // Create your reducer
 * const [state, thunkDispatch] = useThunkReducer(dynamicStateReducer, initialState);
 * const { searchBoxOptions, financialKeysTooltips, choosenPlan } = useMemo(() => state, [state]);
 * ...
 * // Example component setState functions
 * const setChoosenPlan = useCallback((newState) => {
 * handleSetChoosenPlan(thunkDispatch, newState);
 * }, [thunkDispatch]);
 * const setAllIndexReducer = useCallback((newState) => {
 * handleSetAll(thunkDispatch, newState);
 * }, [thunkDispatch]);
 * // Example usage of setState function
 * useEffect(() => {
 * if (choosenPlan !== 'Free') {
 * const choosenPeriod = pricingAnnually ? 'Annually' : 'Monthly';
 * const choosenType = choosenPlan.split('-')[0];
 * setChoosenPlan(`${choosenType}-${choosenPeriod}`);
 * // Can also pass a callback that gets the current state as first argument
 * setChoosenPlan((currentState) => `${currentState}-${choosenPeriod}`);
 * }
 * }, [pricingAnnually]);
 * // Example usage of setAll function
 * useEffect(() => {
 * const promie = async () => axios.DoSomething().then((res) => res);
 * ...
 * Promise.all([promie(), promie2(), promie3()]).then((res) => {
 * const newState = {
 * 	searchBoxOptions: res[0],
 * 	financialKeysTooltips: res[1],
 * 	choosenPlan: res[2],
 * };
 * setAllIndexReducer(newState);
 * });
 * }, []);
 */

const dynamicStateReducer = (state, action) => {
  const { SET_NEW_STATE } = reducerActions;
  const { type = '', payload = {} } = action;
  if (type === SET_NEW_STATE) {
    const newValues = {};
    const keys = Object.keys(payload);
    const values = Object.values(payload);
    keys.forEach((key, index) => {
      if (isFunction(values[index])) {
        newValues[key] = values[index](state[key]);
      } else {
        newValues[key] = values[index];
      }
    });
    return {
      ...state,
      ...newValues,
    };
  }
  return state;
};

/**
 * @function handleSetReducerState
 * @param {function} dispatch - **--IGNORE IF CALLED IN A THUNK DISPATCH, ELSE USE THE THUNK DISPATCH FROM THE THUNK REDUCER HOOK--** the dispatch function to be called.
 * @param {object} newState - **State value can be a function that recieve the current state as the first parameter!** Object with fields corresponding to the current state fields. **--If the fields dont exist they will be created!--**.
 * @example <caption>Setting new state</caption>
 * const handleSetConfig = (dispatch, config) => {
 * handleSetReducerState(dispatch, {
 * config: (currentState) =>
 * currentState.map((item) =>
 * item.name === config.name
 * ? {
 * ...item,
 * value: config.value,
 * }
 * : item
 * ),
 * });
 * };
 */

const handleSetReducerState = (dispatch, newState) => {
  dispatch({
    type: reducerActions.SET_NEW_STATE,
    payload: newState,
  });
};

/**
 * @function useThunkReducer
 * @description **--Please use only with the {@link dynamicStateReducer}! The dispatch will be used as the first arguemnt for the function (if a function was passed to the thunk dispatch), use compatible functions only!--**, This hook allow you to pass either an action string or a function to dispatch.
 * @example <caption>Example usage with argless function (only dispatch required)</caption>
 * const reducerFunction = (dispatch) => {
 *   dispatch({ type });
 * }
 *
 * const wrapperFunction = () => {
 *   thunkDispatch(reducerFunction);
 * }
 * @example <caption>Example usage with argfull function (more then just dispatch required)</caption>
 * const reducerFunction = (dispatch, ...args) => {
 *   dispatch({ type, payload });
 * }
 *
 * const wrapperFunction = (...args) => {
 *   reducerFunction(thunkDispatch, ...args);
 * }
 * @example <caption>Example usage 2 with argfull function (more then just dispatch required)</caption>
 * const reducerFunction = (dispatch, ...args) => {
 *   dispatch({ type, payload });
 * }
 *
 * const wrapperFunction = (...args) => {
 *   thunkDispatch(reducerFunction, ...args);
 * }
 *
 * @param {function} reducer - The reducer to be used.
 * @param {object} initState - Object with the initial state for the reducer.
 * @returns {[object, function]} An array with a state object and the thunkDispatch function.
 */

const useThunkReducer = (reducer, initState) => {
  const [state, dispatch] = useReducer(reducer, initState);

  const thunkDispatch = (action, ...args) => {
    if (isFunction(action)) {
      action(dispatch, ...args);
    } else {
      dispatch(action);
    }
  };

  return [state, thunkDispatch];
};

export { reducerActions, dynamicStateReducer, handleSetReducerState, useThunkReducer };
