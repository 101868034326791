import React from 'react';
import HeaderNav from './HeaderNav';

export default function Header() {
  return (
    <header className="header">
      <HeaderNav />
    </header>
  );
}
