import backendApi from '../assets/backendApi';

/**
 * @function useFetch
 * @description This function will call a fetch request and dispatch actions based on functionOptions.
 * @param {function} dispatch - The dispatch function to be called.
 * @param {string} url - The url of the request.
 * @param {{
 * method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'OPTIONS' | string;
 * headers:? {};
 * body:? JSON;
 * credentials:? 'include';
 * }} options - Option for the feth request.
 * @param {object} functionOptions - Options for the function.
 * @param {boolean} silent - Set true for fetch request that doesnt return images.
 * @param {boolean} auth - Set true for authorization request.
 * @returns The parsed response or the error, if auth set to true error wont be dispatched.
 */

const useFetch = (url, options, functionOptions) => {
  const { auth = false, image = false, blob = false } = functionOptions || {};
  const fetchUrl = async () => {
    try {
      const response = await fetch(`${backendApi}${url}`, options);
      if (image) {
        return response;
      }
      if (blob) {
        const blobData = await response.blob();
        return blobData;
      }
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message);
      }
      return data;
    } catch (error) {
      if (auth) {
        return error;
      }
      return error;
    }
  };

  return fetchUrl();
};

export default useFetch;
