import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

export default function HeaderNavItem(props) {
  const { title, link } = props;
  const location = useLocation();
  const currentQuery = useMemo(() => location.pathname, [location.pathname]);
  const isActive = useMemo(() => currentQuery === link, [currentQuery, link]);
  return (
    <li className="header__nav-link">
      <Link className={`header__nav-link_link${isActive ? ' active' : ''}`} to={link}>
        {title}
      </Link>
    </li>
  );
}

HeaderNavItem.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};
