import { createContext, useContext } from 'react';

const CurrentUserContext = createContext();

/**
 * @function useCurrentUserContext
 * @return {{
 * currentUser: {
 * userName: String;
 * email: String;
 * };
 * setCurrentUser: Function;
 * }}
 */

const useCurrentUserContext = () => useContext(CurrentUserContext);

export { CurrentUserContext, useCurrentUserContext };
